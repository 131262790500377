<template>
  <v-container>
    <v-row
      v-if="user"
      class="justify-center align-self-start"
    >
      <v-col
        class="col-sm-9 col-md-5 col-lg-6 col-9 mt-3"
      >
        <welcome-card />

        <network-list />
      </v-col>

      <v-col
        class="col-sm-9 col-md-5 col-lg-6 col-9 mt-3"
      >
        <release-brief />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'Dashboard',
  components: {
    NetworkList: () => import('src/dashboard/components/NetworkList'),
    ReleaseBrief: () => import('src/dashboard/components/ReleaseBrief'),
    WelcomeCard: () => import('src/dashboard/components/WelcomeCard'),
  },
  props: {
    query: {
      type: Object,
      default: () => ({}),
    },
    reportId: {
      type: String,
      default: null,
    },
    reportName: {
      type: String,
      default: 'dashboard',
    },
    subId: {
      type: String,
      default: '*',
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  watch: {
    $route(to) {
      return (to === 'dashboard' && this.setup()) || false
    },
  },
  created() {
    this.setup()
  },
  methods: {
    async setup() {
      const { reportId, reportName, subId } = this
      const params = { reportId, reportName, subId }
      const columns = { dashboard: [] }

      this.$store.dispatch('tools/setup', { columns, params })
    },
  },
}
</script>
